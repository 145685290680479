import React from "react";
import classNames from "classnames";
import {Container} from "reactstrap";
import {Switch, Route, Redirect} from "react-router-dom";

import Topbar from "./Topbar";
import Credentials from "./Credentials";
import GetPlatformStats from "../Requests/GetPlatformStats";
import SetPlatformStats from "../Requests/SetPlatformStats";
import PremiumOffersValidation from "../Requests/PremiumOffersValidation";
import GetPlatformProjects from "../Requests/GetPlatformProjects";
import CreatePlatformProject from "../Requests/CreatePlatformProject";
import EditPlatformProject from "../Requests/EditPlatformProject";
import {useCredentials} from "../../App";

const Content = ( {sidebarIsOpen, toggleSidebar} ) => {
    const credentials = useCredentials();
    return (
        <Container
            fluid
            className={classNames( "content", {"is-open": sidebarIsOpen} )}
        >
            <Topbar toggleSidebar={toggleSidebar}/>


            <Switch>
                <Route exact path="/credentials">
                    <Credentials/>
                </Route>

                {
                    credentials !== null && (
                        <>
                            <Route exact path="/get-platform-stats">
                                <GetPlatformStats/>
                            </Route>
                            <Route exact path="/set-platform-stats">
                                <SetPlatformStats/>
                            </Route>
                            <Route exact path="/premium-offers-validation">
                                <PremiumOffersValidation/>
                            </Route>
                            <Route exact path="/get-platform-projects">
                                <GetPlatformProjects/>
                            </Route>
                            {/*<Route exact path="/create-platform-project">*/}
                            {/*    <CreatePlatformProject/>*/}
                            {/*</Route>*/}
                            <Route exact path="/edit-platform-project">
                                <EditPlatformProject/>
                            </Route>
                        </>
                    )
                }
                <Route>
                    <Redirect
                        to="/credentials"
                    />
                </Route>
            </Switch>
        </Container>
    )
};

export default Content;
