import React, {useState, useEffect} from 'react';
import {FormGroup, Input, Label} from "reactstrap";

export default function CurrencySelect( props ) {
    const [value, setValue] = useState( props.value );
    useEffect( () => {
        setValue( props.value );
    }, [props.value] )

    const currencies = [
        {
            "value": "Select",
            "id":    null
        },
        {
            "value": "EUR",
            "id":    1
        },
        {
            "value": "USD",
            "id":    2
        },
        {
            "value": "GBP",
            "id":    3
        },
        {
            "value": "ALL",
            "id":    4
        },
        {
            "value": "AMD",
            "id":    5
        },
        {
            "value": "AZN",
            "id":    6
        },
        {
            "value": "BYN",
            "id":    7
        },
        {
            "value": "BAM",
            "id":    8
        },
        {
            "value": "BGN",
            "id":    9
        },
        {
            "value": "HRK",
            "id":    10
        },
        {
            "value": "EUR",
            "id":    11
        },
        {
            "value": "CZK",
            "id":    12
        },
        {
            "value": "DKK",
            "id":    13
        },
        {
            "value": "GEL",
            "id":    14
        },
        {
            "value": "HUF",
            "id":    15
        },
        {
            "value": "ISK",
            "id":    16
        },
        {
            "value": "CHF",
            "id":    17
        },
        {
            "value": "MDL",
            "id":    18
        },
        {
            "value": "MKD",
            "id":    19
        },
        {
            "value": "NOK",
            "id":    20
        },
        {
            "value": "PLN",
            "id":    21
        },
        {
            "value": "RON",
            "id":    22
        },
        {
            "value": "RUB",
            "id":    23
        },
        {
            "value": "RSD",
            "id":    24
        },
        {
            "value": "SEK",
            "id":    25
        },
        {
            "value": "CHF",
            "id":    26
        },
        {
            "value": "TRY",
            "id":    27
        },
        {
            "value": "UAH",
            "id":    28
        },
        {
            "value": "ZAR",
            "id":    29
        }
    ];

    return (
        <Input type="select" name="select" id="currency" value={value} onChange={( e ) => {
            setValue( e.target.value );
            props.handleChange( e.target.value );
        }}>
            {
                currencies.map( ( currency ) => (
                    <option key={currency.id} value={currency.id}>{currency.value}</option>
                ) )
            }
        </Input>
    )
}