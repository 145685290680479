import React, {useState} from 'react';
import {Alert, Button, Col, Form, FormGroup, Input, Jumbotron, Label, Row} from "reactstrap";
import axios from "axios";
import {useCredentials} from "../../App";

export default function GetPlatformProjects() {
    const credentials = useCredentials();

    const [loading, setLoading] = useState( false );
    const [error, setError] = useState( null );
    const [data, setData] = useState( null );


    return (
        <div className="py-5">
            <h2>Get Platform Projects</h2>

            <Jumbotron style={{padding: '2rem'}}>
                <Row>
                    <Col sm={2}>URL:</Col>
                    <Col sm={10}><pre>{ `${credentials.server}/api/platform/projects` }</pre></Col>
                </Row>
                <Row>
                    <Col sm={2}>Method:</Col>
                    <Col sm={10}><pre>GET</pre></Col>
                </Row>
            </Jumbotron>

            {
                error !== null && (
                    <Alert color="danger">
                        <pre>{JSON.stringify( error, null, 4 )}</pre>
                    </Alert>
                )
            }

            {
                loading ? (
                    <div className="py-5">
                        Loading...
                    </div>
                ) : (
                    <Form onSubmit={( e ) => {
                        e.preventDefault();
                        setError( null );
                        setLoading( true );

                        console.log( credentials );

                        axios.get( `${credentials.server}/api/platform/projects`, {
                            headers: {
                                'Authorization': `Bearer ${credentials.token}`
                            }
                        } )
                            .then( function ( response ) {
                                setLoading( false );
                                setData( response.data );
                            } )
                            .catch( function ( error ) {
                                setLoading( false );
                                setError( error.response.data );
                            } );
                    }}>
                        <Button type="submit">Get!</Button>
                    </Form>
                )
            }


            {
                data !== null && (
                    <Alert color="info" className="mt-5">
                        <pre>{JSON.stringify( data, null, 4 )}</pre>
                    </Alert>
                )
            }
        </div>
    )
}