import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faUnlockAlt,
    faBuilding,
    faHome,
    faHouseUser,
    faCommentDollar
} from "@fortawesome/free-solid-svg-icons";
import {NavItem, NavLink, Nav} from "reactstrap";
import classNames from "classnames";
import {Link} from "react-router-dom";

import SubMenu from "./SubMenu";
import {useCredentials} from "../../App";

const SideBar = ( {isOpen, toggle} ) => {
    const credentials = useCredentials();

    return (
        <div className={classNames( "sidebar", {"is-open": isOpen} )}>
            <div className="sidebar-header">
                  <span color="info" onClick={toggle} style={{color: "#fff"}}>
                    &times;
                  </span>
                <img src="https://brikkapp.test/images/logos/brikkapp05.svg" alt=""/>
                <h3>API Demo</h3>
            </div>
            <div className="side-menu">
                <Nav vertical className="list-unstyled pb-3">
                    <NavItem>
                        <NavLink tag={Link} to={"/credentials"}>
                            <FontAwesomeIcon icon={faUnlockAlt} className="mr-2"/>
                            Credentials
                        </NavLink>
                    </NavItem>

                    {
                        credentials !== null && (
                            <>
                                <SubMenu title="Platforms" icon={faHome} items={
                                    [
                                        {
                                            title:  'Get Platform Stats',
                                            target: 'get-platform-stats'
                                        },
                                        {
                                            title:  'Set Platform Stats',
                                            target: 'set-platform-stats'
                                        },
                                    ]}
                                />
                                <NavItem>
                                    <NavLink tag={Link} to={"/premium-offers-validation"}>
                                        <FontAwesomeIcon icon={faCommentDollar} className="mr-2"/>
                                        Premium Offers
                                    </NavLink>
                                </NavItem>
                                <SubMenu title="Projects" icon={faHouseUser} items={
                                    [
                                        {
                                            title:  'Get Projects',
                                            target: 'get-platform-projects'
                                        },
                                        // {
                                        //     title:  'Create Project',
                                        //     target: 'create-platform-project'
                                        // },
                                        {
                                            title:  'Edit Project',
                                            target: 'edit-platform-project'
                                        }
                                    ]}
                                />
                            </>
                        )
                    }
                </Nav>
            </div>
        </div>
    )
};

export default SideBar;
