export const status_values = [
    "planned", "funding open", "funded", "not funded", "paid out"
];

export const investment_types = [
    "equity", "debt"
];

export const project_types = [
    "existing", "development"
];

export const property_types = [
    "residential", "office", "land", "retail", "logistics", "industrial"
];


export const yield_distributions = [
    "annually", "quarterly", "monthly"
];

export const loan_ratings = [
    'AAA',
    'AA+',
    'AA',
    'AA−',
    'A+',
    'A',
    'A−',
    'BBB+',
    'BBB',
    'BBB−',
    'BB+',
    'BB',
    'BB−',
    'B+',
    'B',
    'B−v',
    'CCC+',
    'CCC',
    'CCC−',
    'CC',
    'C',
    'D'
];