import React, {useState} from 'react';
import {
    Alert,
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Jumbotron,
    Label,
    Row
} from "reactstrap";
import axios from "axios";
import CurrencySelect from "./components/CurrencySelect";

import {
    status_values,
    investment_types,
    project_types,
    property_types,
    yield_distributions, loan_ratings
} from "../../config/project";
import {countries} from "../../config/countries";
import {useCredentials} from "../../App";

export default function EditPlatformProject( props ) {
    const credentials = useCredentials();

    const [loading, setLoading] = useState( false );
    const [error, setError] = useState( null );
    const [data, setData] = useState( null );

    const [slug, setSlug] = useState( '' );
    const [name, setName] = useState( '' );
    const [countryId, setCountry] = useState( '' );
    const [city, setCity] = useState( '' );
    const [totalVolume, setTotalVolume] = useState( '' );
    const [currency, setCurrency] = useState( '' );
    const [yieldValue, setYieldValue] = useState( '' );
    const [yieldDistribution, setYieldDistribution] = useState( '' );

    const [investmentType, setInvestmentType] = useState( '' );
    const [projectType, setProjectType] = useState( '' );
    const [propertyType, setPropertyType] = useState( '' );
    const [minimumInvestment, setMinimumInvestment] = useState( '' );
    const [maximumInvestment, setMaximumInvestment] = useState( '' );
    const [open, setOpen] = useState( '' );
    const [close, setClose] = useState( '' );
    const [repayment, setRepayment] = useState( '' );
    const [repaymentActual, setRepaymentActual] = useState( '' );

    const [publicationDaysAdvance, setPublicationDaysAdvance] = useState( '' );

    const [realEstateProject, setRealEstateProject] = useState( 0 );

    const [loanRating, setLoanRating] = useState();
    const [ltv, setLtv] = useState();


    const [investors, setInvestors] = useState( '' );
    const [volumeLeft, setVolumeLeft] = useState( '' );
    const [volumeInvested, setVolumeInvested] = useState( '' );

    const setValues = ( data ) => {
        setName( data.name );
        setCountry( data.country );

        setCity( data.city );

        setTotalVolume( data.total_volume );
        setCurrency( data.currency_id );

        setYieldValue( data.yield );
        setYieldDistribution( data.yield_distribution );

        setInvestmentType( data.investment_type );
        setProjectType( data.project_type );
        setPropertyType( data.property_type );
        setMinimumInvestment( data.minimum_investment );
        setMaximumInvestment( data.maximum_investment );

        setOpen( data.open );
        setClose( data.close );
        setRepayment( data.repayment );
        setRepaymentActual( data.repayment_actual ? data.repayment_actual : '' );

        setRealEstateProject( data.real_estate_project );
        setLoanRating( data.loan_rating );
        setLtv( data.ltv );

        setPublicationDaysAdvance( data.publication_days_advance );
        setInvestors( data.investors );
        setVolumeLeft( data.volume_left );
        setVolumeInvested( data.volume_invested );

    }

    const loadCurrentData = ( e ) => {
        e.preventDefault();

        axios.get( `${credentials.server}/api/platform/projects/${slug}`, {
            headers: {
                'Authorization': `Bearer ${credentials.token}`
            }
        } )
            .then( function ( response ) {
                setLoading( false );
                console.log( response.data );
                setValues( response.data );

            } )
            .catch( function ( error ) {
                setLoading( false );
                setError( error.response.data );
            } );
    }


    if ( loading ) {
        return (
            <div className="py-5">
                Loading...
            </div>
        )
    }

    console.log( {loanRating} );

    return (
        <div className="py-5">
            <h2>Edit platform project</h2>
            <Jumbotron style={{padding: '2rem'}}>
                <Row>
                    <Col sm={2}>URL:</Col>
                    <Col sm={10}>
                        <pre>{`${credentials.server}/api/platform/projects/{slug}`}</pre>
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}>Method:</Col>
                    <Col sm={10}>
                        <pre>PUT</pre>
                    </Col>
                </Row>
            </Jumbotron>

            {
                error !== null && (
                    <Alert color="danger">
                        <pre>{JSON.stringify( error, null, 4 )}</pre>
                    </Alert>
                )
            }

            {
                data !== null && (
                    <Alert color="info" className="mt-5">
                        <pre>{JSON.stringify( data, null, 4 )}</pre>
                    </Alert>
                )
            }

            <Form onSubmit={( e ) => {
                e.preventDefault();
                setError( null );
                setLoading( true );

                const data = {
                    "slug":                slug,
                    "name":                name,
                    "country":             countryId,
                    "city":                city,
                    "total_volume":        totalVolume,
                    "currency_id":         currency,
                    "yield":               yieldValue,
                    "yield_distribution":  yieldDistribution,
                    "investment_type":     investmentType,
                    "project_type":        projectType,
                    "property_type":       propertyType,
                    "minimum_investment":  minimumInvestment,
                    "maximum_investment":  maximumInvestment,
                    "open":                open,
                    "close":               close,
                    "repayment":           repayment,
                    "investors":           investors,
                    "volume_left":         volumeLeft,
                    "volume_invested":     volumeInvested,
                    "repayment_actual":    repaymentActual,
                    "real_estate_project": realEstateProject,
                    "loan_rating":         loanRating,
                    "ltv":                 ltv,
                    "publication_days_advance": publicationDaysAdvance

                }

                axios.put( `${credentials.server}/api/platform/projects/${slug}`, data, {
                    headers: {
                        'Authorization': `Bearer ${credentials.token}`
                    }
                } )
                    .then( function ( response ) {
                        setLoading( false );
                        setData( response.data );
                    } )
                    .catch( function ( error ) {
                        setLoading( false );
                        setError( error.response.data );
                    } );
            }}>
                <FormGroup row>
                    <Label for="slug" sm={2}>slug</Label>
                    <Col sm={4}>
                        <InputGroup>

                            <Input type="text" name="slug" id="slug" value={slug}
                                   onChange={( e ) => setSlug( e.target.value )}/>
                            <InputGroupAddon addonType="append">
                                <Button
                                    onClick={loadCurrentData}
                                >
                                    Load current data
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>

                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="name" sm={2}>name</Label>
                    <Col sm={4}>
                        <Input type="text" name="name" id="name" value={name}
                               onChange={( e ) => setName( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="countryId" sm={2}>country</Label>
                    <Col sm={4}>
                        <Input type="select" id="countryId" value={countryId}
                               onChange={( e ) => setCountry( e.target.value )}>
                            {
                                countries.map( ( country ) => (
                                    <option key={country.code} value={country.code}>{country.name}</option>
                                ) )
                            }
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="city" sm={2}>city</Label>
                    <Col sm={4}>
                        <Input type="text" id="city" value={city}
                               onChange={( e ) => setCity( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="totalVolume" sm={2}>totalVolume</Label>
                    <Col sm={4}>
                        <Input type="text" name="totalVolume" id="totalVolume" value={totalVolume}
                               onChange={( e ) => setTotalVolume( e.target.value )}/>
                    </Col>
                    <Col sm={4}>
                        <CurrencySelect value={currency} handleChange={( value ) => setCurrency( value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="yield" sm={2}>yield</Label>
                    <Col sm={4}>
                        <Input type="text" name="yield" id="yield" value={yieldValue}
                               onChange={( e ) => setYieldValue( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="yieldDistribution" sm={2}>yieldDistribution</Label>
                    <Col sm={4}>
                        <Input type="select" id="yieldDistribution" value={yieldDistribution}
                               onChange={( e ) => setYieldDistribution( e.target.value )}>
                            <option value="">Please Select</option>
                            {
                                yield_distributions.map( ( distribution ) => (
                                    <option value={distribution}>{distribution}</option>
                                ) )
                            }
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="investmentType" sm={2}>investmentType</Label>
                    <Col sm={4}>
                        <Input type="select" id="investmentType" value={investmentType}
                               onChange={( e ) => setInvestmentType( e.target.value )}>
                            <option value="">Please Select</option>
                            {
                                investment_types.map( ( type ) => (
                                    <option value={type}>{type}</option>
                                ) )
                            }
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="projectType" sm={2}>projectType</Label>
                    <Col sm={4}>
                        <Input type="select" id="projectType" value={projectType}
                               onChange={( e ) => setProjectType( e.target.value )}>
                            <option value="">Please Select</option>
                            {
                                project_types.map( ( type ) => (
                                    <option value={type}>{type}</option>
                                ) )
                            }
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="propertyType" sm={2}>propertyType</Label>
                    <Col sm={4}>
                        <Input type="select" id="propertyType" value={propertyType}
                               onChange={( e ) => setPropertyType( e.target.value )}>
                            <option value="">Please Select</option>
                            {
                                property_types.map( ( type ) => (
                                    <option value={type}>{type}</option>
                                ) )
                            }
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="minimumInvestment" sm={2}>minimumInvestment</Label>
                    <Col sm={4}>
                        <Input type="text" name="minimumInvestment" id="minimumInvestment" value={minimumInvestment}
                               onChange={( e ) => setMinimumInvestment( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="maximumInvestment" sm={2}>maximumInvestment</Label>
                    <Col sm={4}>
                        <Input type="text" name="maximumInvestment" id="maximumInvestment" value={maximumInvestment}
                               onChange={( e ) => setMaximumInvestment( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="open" sm={2}>open</Label>
                    <Col sm={4}>
                        <Input type="text" name="open" id="open" value={open}
                               onChange={( e ) => setOpen( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="close" sm={2}>close</Label>
                    <Col sm={4}>
                        <Input type="text" name="close" id="close" value={close}
                               onChange={( e ) => setClose( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="repayment" sm={2}>repayment</Label>
                    <Col sm={4}>
                        <Input type="text" name="repayment" id="repayment" value={repayment}
                               onChange={( e ) => setRepayment( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="repayment_actual" sm={2}>repayment actual</Label>
                    <Col sm={4}>
                        <Input type="text" name="repayment_actual" id="repayment_actual" value={repaymentActual}
                               onChange={( e ) => setRepaymentActual( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="publication_days_advance" sm={2}>Publication Days Advance</Label>
                    <Col sm={4}>
                        <Input type="select" name="publication_days_advance" id="publication_days_advance"
                               value={publicationDaysAdvance}
                               onChange={( e ) => setPublicationDaysAdvance( parseInt( e.target.value ) )}
                        >
                            <option value="">Please Select</option>
                            <option value="7">7</option>
                            <option value="14">14</option>
                            <option value="21">21</option>
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="real_estate_project" sm={2}>Real Estate Project</Label>
                    <Col sm={4}>
                        <Input type="select" name="real_estate_project" id="real_estate_project"
                               value={realEstateProject}
                               onChange={( e ) => setRealEstateProject( parseInt( e.target.value ) )}
                        >
                            <option value="">Please Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="loan_rating" sm={2}>Loan rating</Label>
                    <Col sm={4}>
                        <Input type="select" id="loan_rating" value={loanRating}
                               onChange={( e ) => setLoanRating( e.target.value )}>
                            <option value="">Please Select</option>
                            {
                                loan_ratings.map( ( type ) => (
                                    <option value={type}>{type}</option>
                                ) )
                            }
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="repayment_actual" sm={2}>LTV</Label>
                    <Col sm={4}>
                        <Input type="number" name="ltv" id="ltv" value={ltv}
                               onChange={( e ) => setLtv( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="investors" sm={2}>investors</Label>
                    <Col sm={4}>
                        <Input type="text" name="investors" id="investors" value={investors}
                               onChange={( e ) => setInvestors( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="volumeLeft" sm={2}>volumeLeft</Label>
                    <Col sm={4}>
                        <Input type="text" name="volumeLeft" id="volumeLeft" value={volumeLeft}
                               onChange={( e ) => setVolumeLeft( e.target.value )}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="volumeInvested" sm={2}>volumeInvested</Label>
                    <Col sm={4}>
                        <Input type="text" name="volumeInvested" id="volumeInvested" value={volumeInvested}
                               onChange={( e ) => setVolumeInvested( e.target.value )}/>
                    </Col>
                </FormGroup>

                <Button type="submit">Set!</Button>
            </Form>
        </div>
    )
}