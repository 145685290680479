import React, {useState} from 'react';
import {Alert, Button, Col, Form, FormGroup, Input, Label} from "reactstrap";
import axios from "axios";
import CurrencySelect from "./components/CurrencySelect";
import {useCredentials} from "../../App";

export default function SetPlatformStats( props ) {
    const credentials = useCredentials();

    const [loading, setLoading] = useState( false );
    const [error, setError] = useState( null );
    const [data, setData] = useState( null );

    const [allProjectsVolume, setAllProjectsVolume] = useState( '' );
    const [allProjectsVolumeCurrencyId, setAllProjectsVolumeCurrencyId] = useState( '' );
    const [completedInvestments, setCompletedInvestments] = useState( '' );
    const [totalUsers, setTotalUsers] = useState( '' );
    const [totalMembers, setTotalMembers] = useState( '' );
    const [benefitsReturned, setBenefitsReturned] = useState( '' );
    const [benefitsReturnedCurrencyId, setBenefitsReturnedCurrencyId] = useState( '' );
    const [averageIrr, setAverageIrr] = useState( '' );

    if ( loading ) {
        return (
            <div className="py-5">
                Loading...
            </div>
        )
    }

    return (
        <div className="py-5">
            <h2>Set Platform Statistics</h2>
            <p>This endpoint retrieves current values of important Platform statistics.</p>

            {
                error !== null && (
                    <Alert color="danger">
                        <pre>{JSON.stringify( error, null, 4 )}</pre>
                    </Alert>
                )
            }


            <Form onSubmit={( e ) => {
                e.preventDefault();
                setError( null );
                setLoading( true );

                const data = {
                    "total_projects_volume": allProjectsVolume,
                    "total_projects_volume_currency_id": allProjectsVolumeCurrencyId,
                    "completed_investments": completedInvestments,
                    "total_users": totalUsers,
                    "total_members": totalMembers,
                    "benefits_returned_volume": benefitsReturned ,
                    "benefits_returned_volume_currency_id": benefitsReturnedCurrencyId,
                    "average_irr": averageIrr ,
                }

                axios.post( `${credentials.server}/api/platform/stats`, data, {
                    headers: {
                        'Authorization': `Bearer ${credentials.token}`
                    }
                } )
                    .then( function ( response ) {
                        setLoading( false );
                        setData( response.data );
                    } )
                    .catch( function ( error ) {
                        setLoading( false );
                        setError( error.response.data );
                    } );
            }}>
                <FormGroup row>
                    <Label for="allProjectsVolume" sm={2}>allProjectsVolume</Label>
                    <Col sm={4}>
                        <Input type="text" name="allProjectsVolume" id="allProjectsVolume" value={allProjectsVolume}
                               onChange={( e ) => {
                                   setAllProjectsVolume( e.target.value )
                               }}/>
                    </Col>
                    <Col sm={4}>
                        <CurrencySelect handleChange={(value) => setAllProjectsVolumeCurrencyId(value)}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="completedInvestments" sm={2}>completedInvestments</Label>
                    <Col sm={4}>
                        <Input type="text" name="completedInvestments" id="completedInvestments"
                               value={completedInvestments} onChange={( e ) => {
                            setCompletedInvestments( e.target.value )
                        }}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="allProjectsVolume" sm={2}>totalUsers</Label>
                    <Col sm={4}>
                        <Input type="text" name="totalUsers" id="totalUsers" value={totalUsers} onChange={( e ) => {
                            setTotalUsers( e.target.value )
                        }}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="totalMembers" sm={2}>totalMembers</Label>
                    <Col sm={4}>
                        <Input type="text" name="totalMembers" id="totalMembers" value={totalMembers}
                               onChange={( e ) => {
                                   setTotalMembers( e.target.value )
                               }}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="benefitsReturned" sm={2}>benefitsReturned</Label>
                    <Col sm={4}>
                        <Input type="text" name="benefitsReturned" id="benefitsReturned" value={benefitsReturned}
                               onChange={( e ) => {
                                   setBenefitsReturned( e.target.value )
                               }}/>
                    </Col>
                    <Col sm={4}>
                        <CurrencySelect handleChange={(value) => setBenefitsReturnedCurrencyId(value)}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="averageIrr" sm={2}>averageIrr</Label>
                    <Col sm={4}>
                        <Input type="text" name="averageIrr" id="averageIrr" value={averageIrr} onChange={( e ) => {
                            setAverageIrr( e.target.value )
                        }}/>
                    </Col>
                </FormGroup>

                <Button type="submit">Set!</Button>
            </Form>

            {
                data !== null && (
                    <Alert color="info" className="mt-5">
                        <pre>{JSON.stringify( data, null, 4 )}</pre>
                    </Alert>
                )
            }
        </div>
    )
}