import React, {useState} from 'react';
import {Alert, Button, Col, Form, FormGroup, Input, Label} from "reactstrap";
import axios from "axios";
import {useCredentials} from "../../App";

export default function GetPlatformStats() {
    const credentials = useCredentials();

    const [loading, setLoading] = useState( false );
    const [error, setError] = useState( null );
    const [data, setData] = useState( null );

    if ( loading ) {
        return (
            <div className="py-5">
                Loading...
            </div>
        )
    }

    return (
        <div className="py-5">
            <h2>Get Platform Statistics</h2>
            <p>This endpoint retrieves current values of important Platform statistics.</p>

            {
                error !== null && (
                    <Alert color="danger">
                        <pre>{JSON.stringify( error, null, 4 )}</pre>
                    </Alert>
                )
            }


            <Form onSubmit={( e ) => {
                e.preventDefault();
                setError( null );
                setLoading( true );

                console.log( credentials );

                axios.get( `${credentials.server}/api/platform/stats`, {
                    headers: {
                        'Authorization': `Bearer ${credentials.token}`
                    }
                } )
                    .then( function ( response ) {
                        setLoading( false );
                        setData( response.data );
                    } )
                    .catch( function ( error ) {
                        setLoading( false );
                        setError( error.response.data );
                    } );
            }}>
                <Button type="submit">Get!</Button>
            </Form>

            {
                data !== null && (
                    <Alert color="info" className="mt-5">
                        <pre>{JSON.stringify( data, null, 4 )}</pre>
                    </Alert>
                )
            }
        </div>
    )
}