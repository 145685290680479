import React, {useEffect, useState} from 'react';
import {Button, Form, FormGroup, Label, Input, FormText, Col, Row, Alert, Jumbotron, Container} from 'reactstrap';
import axios from 'axios';
import {useCredentials, useSetCredentials} from "../../App";


function Credentials() {
    const setCredentials = useSetCredentials();
    const credentials = useCredentials();


    const [loading, setLoading] = useState( false );
    const [error, setError] = useState( false );
    const [token, setToken] = useState( null );
    const [expires, setExpires] = useState( null );
    const [tokenType, setTokenType] = useState( null );

    const [url, setUrl] = useState( 'https://brikkapp.com' );
    const [urlOther, setUrlOther] = useState( 'https://brikkapp.test' );

    const [clientId, setClientId] = useState( '' );
    const [clientSecret, setClientSecret] = useState( '' );

    useEffect( () => {
        if ( credentials !== null ) {
            setToken( credentials.token );
            setUrl( credentials.server );
            setClientId( credentials.clientId );
            setClientSecret( credentials.clientSecret );
        }

    }, [credentials] )

    if ( loading ) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    if ( token ) {
        return (
            <div>
                <Row>
                    <Col sm={2}>Server:</Col>
                    <Col sm={10}>{url === 'other' ? urlOther : url}</Col>
                </Row>
                <Row>
                    <Col sm={2}>Client Id:</Col>
                    <Col sm={10}>{clientId}</Col>
                </Row>
                <Row>
                    <Col sm={2}>Client Secret:</Col>
                    <Col sm={10}>{clientSecret}</Col>
                </Row>
                <hr/>
                <Row>
                    <Col sm={2}>Token:</Col>
                    <Col sm={10}>
                        <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>{token}</pre>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <>
            {
                error && (
                    <Alert color="danger">
                        <pre>{JSON.stringify( error, null, 4 )}</pre>
                    </Alert>
                )
            }


            <Form onSubmit={( e ) => {
                e.preventDefault();
                setError( null );
                setLoading( true );
                axios.post( `${url === 'other' ? urlOther : url}/oauth/token`, {
                    grant_type:    "client_credentials",
                    scope:         " * ",
                    client_id:     clientId,
                    client_secret: clientSecret
                }, {
                    auth: {
                        username: "dev",
                        password: "jenpronas"
                    }
                } )
                    .then( function ( response ) {
                        setLoading( false );
                        setTokenType( response.data.token_type );
                        setExpires( response.data.expires_in );
                        setToken( response.data.access_token );

                        setCredentials( {
                            server: url === 'other' ? urlOther : url,
                            clientId,
                            clientSecret,
                            token:  response.data.access_token
                        } )
                        console.log( response.data );
                    } )
                    .catch( function ( error ) {
                        setLoading( false );
                        setError( error.response.data );
                        console.log( error.response.data );
                    } );
            }}>
                <legend>API Url</legend>
                <FormGroup check>
                    <Label check>
                        <Input type="radio" name="url" value={'https://brikkapp.com'}
                            checked={url === 'https://brikkapp.com'}
                            onChange={( e ) => {
                                setUrl( e.target.value )
                            }}/>
                        Production server
                    </Label>
                </FormGroup>
                <FormGroup row>
                    <Col sm={2}>
                        <Label check style={{paddingLeft: '1.25rem'}}>
                            <Input type="radio" name="url" value="other" checked={url === 'other'}
                                   onChange={( e ) => {
                                       setUrl( e.target.value )
                                   }}/>
                            Other
                        </Label>
                    </Col>
                    <Col sm={10}>
                        <Input type="text" name="url_other" value={urlOther} checked={url === 'other'}
                               onChange={( e ) => {
                                   setUrlOther( e.target.value )
                               }} disabled={url !== 'other'}/>
                    </Col>
                </FormGroup>

                <hr/>

                <FormGroup row>
                    <Label for="client_id" sm={2}>Client ID</Label>
                    <Col sm={10}>
                        <Input type="text" name="client_id" id="client_id" value={clientId} onChange={( e ) => {
                            setClientId( e.target.value )
                        }}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="client_secret" sm={2}>Client Secret</Label>
                    <Col sm={10}>

                        <Input type="text" name="client_secret" id="client_secret" value={clientSecret}
                               onChange={( e ) => {
                                   setClientSecret( e.target.value )
                               }}/>
                    </Col>
                </FormGroup>

                <Button type="submit">Submit</Button>
            </Form>
        </>
    )
}

export default function CredentialsWrapper() {
    return (
        <Jumbotron style={{padding: '2rem'}}>
            <h2 className="pb-4">API Credentials</h2>
            <Credentials/>
        </Jumbotron>
    )
}
