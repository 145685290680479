export const countries = [
    {
        "code": "AND",
        "name": "Andorra"
    },
    {
        "code": "ARE",
        "name": "United Arab Emirates"
    },
    {
        "code": "AFG",
        "name": "Afghanistan"
    },
    {
        "code": "ATG",
        "name": "Antigua and Barbuda"
    },
    {
        "code": "AIA",
        "name": "Anguilla"
    },
    {
        "code": "ALB",
        "name": "Albania"
    },
    {
        "code": "ARM",
        "name": "Armenia"
    },
    {
        "code": "ANT",
        "name": "Netherlands Antilles"
    },
    {
        "code": "AGO",
        "name": "Angola"
    },
    {
        "code": "ATA",
        "name": "Antarctica"
    },
    {
        "code": "ARG",
        "name": "Argentina"
    },
    {
        "code": "ASM",
        "name": "American Samoa"
    },
    {
        "code": "AUT",
        "name": "Austria"
    },
    {
        "code": "AUS",
        "name": "Australia"
    },
    {
        "code": "ABW",
        "name": "Aruba"
    },
    {
        "code": "ALA",
        "name": "Aland Islands"
    },
    {
        "code": "AZE",
        "name": "Azerbaijan"
    },
    {
        "code": "BIH",
        "name": "Bosnia and Herzegovina"
    },
    {
        "code": "BRB",
        "name": "Barbados"
    },
    {
        "code": "BGD",
        "name": "Bangladesh"
    },
    {
        "code": "BEL",
        "name": "Belgium"
    },
    {
        "code": "BFA",
        "name": "Burkina Faso"
    },
    {
        "code": "BGR",
        "name": "Bulgaria"
    },
    {
        "code": "BHR",
        "name": "Bahrain"
    },
    {
        "code": "BDI",
        "name": "Burundi"
    },
    {
        "code": "BEN",
        "name": "Benin"
    },
    {
        "code": "BLM",
        "name": "Saint Barthelemy"
    },
    {
        "code": "BMU",
        "name": "Bermuda"
    },
    {
        "code": "BRN",
        "name": "Brunei"
    },
    {
        "code": "BOL",
        "name": "Bolivia"
    },
    {
        "code": "BES",
        "name": "Bonaire, Saint Eustatius and Saba"
    },
    {
        "code": "BRA",
        "name": "Brazil"
    },
    {
        "code": "BHS",
        "name": "Bahamas"
    },
    {
        "code": "BTN",
        "name": "Bhutan"
    },
    {
        "code": "BVT",
        "name": "Bouvet Island"
    },
    {
        "code": "BWA",
        "name": "Botswana"
    },
    {
        "code": "BLR",
        "name": "Belarus"
    },
    {
        "code": "BLZ",
        "name": "Belize"
    },
    {
        "code": "CAN",
        "name": "Canada"
    },
    {
        "code": "CCK",
        "name": "Cocos Islands"
    },
    {
        "code": "COD",
        "name": "Democratic Republic of the Congo"
    },
    {
        "code": "CAF",
        "name": "Central African Republic"
    },
    {
        "code": "COG",
        "name": "Republic of the Congo"
    },
    {
        "code": "CHE",
        "name": "Switzerland"
    },
    {
        "code": "CIV",
        "name": "Ivory Coast"
    },
    {
        "code": "COK",
        "name": "Cook Islands"
    },
    {
        "code": "CHL",
        "name": "Chile"
    },
    {
        "code": "CMR",
        "name": "Cameroon"
    },
    {
        "code": "CHN",
        "name": "China"
    },
    {
        "code": "COL",
        "name": "Colombia"
    },
    {
        "code": "CRI",
        "name": "Costa Rica"
    },
    {
        "code": "SCG",
        "name": "Serbia and Montenegro"
    },
    {
        "code": "CUB",
        "name": "Cuba"
    },
    {
        "code": "CPV",
        "name": "Cape Verde"
    },
    {
        "code": "CUW",
        "name": "Curacao"
    },
    {
        "code": "CXR",
        "name": "Christmas Island"
    },
    {
        "code": "CYP",
        "name": "Cyprus"
    },
    {
        "code": "CZE",
        "name": "Czech Republic"
    },
    {
        "code": "DEU",
        "name": "Germany"
    },
    {
        "code": "DJI",
        "name": "Djibouti"
    },
    {
        "code": "DNK",
        "name": "Denmark"
    },
    {
        "code": "DMA",
        "name": "Dominica"
    },
    {
        "code": "DOM",
        "name": "Dominican Republic"
    },
    {
        "code": "DZA",
        "name": "Algeria"
    },
    {
        "code": "ECU",
        "name": "Ecuador"
    },
    {
        "code": "EST",
        "name": "Estonia"
    },
    {
        "code": "EGY",
        "name": "Egypt"
    },
    {
        "code": "ESH",
        "name": "Western Sahara"
    },
    {
        "code": "ERI",
        "name": "Eritrea"
    },
    {
        "code": "ESP",
        "name": "Spain"
    },
    {
        "code": "ETH",
        "name": "Ethiopia"
    },
    {
        "code": "FIN",
        "name": "Finland"
    },
    {
        "code": "FJI",
        "name": "Fiji"
    },
    {
        "code": "FLK",
        "name": "Falkland Islands"
    },
    {
        "code": "FSM",
        "name": "Micronesia"
    },
    {
        "code": "FRO",
        "name": "Faroe Islands"
    },
    {
        "code": "FRA",
        "name": "France"
    },
    {
        "code": "GAB",
        "name": "Gabon"
    },
    {
        "code": "GBR",
        "name": "United Kingdom"
    },
    {
        "code": "GRD",
        "name": "Grenada"
    },
    {
        "code": "GEO",
        "name": "Georgia"
    },
    {
        "code": "GUF",
        "name": "French Guiana"
    },
    {
        "code": "GGY",
        "name": "Guernsey"
    },
    {
        "code": "GHA",
        "name": "Ghana"
    },
    {
        "code": "GIB",
        "name": "Gibraltar"
    },
    {
        "code": "GRL",
        "name": "Greenland"
    },
    {
        "code": "GMB",
        "name": "Gambia"
    },
    {
        "code": "GIN",
        "name": "Guinea"
    },
    {
        "code": "GLP",
        "name": "Guadeloupe"
    },
    {
        "code": "GNQ",
        "name": "Equatorial Guinea"
    },
    {
        "code": "GRC",
        "name": "Greece"
    },
    {
        "code": "SGS",
        "name": "South Georgia and the South Sandwich Islands"
    },
    {
        "code": "GTM",
        "name": "Guatemala"
    },
    {
        "code": "GUM",
        "name": "Guam"
    },
    {
        "code": "GNB",
        "name": "Guinea-Bissau"
    },
    {
        "code": "GUY",
        "name": "Guyana"
    },
    {
        "code": "HKG",
        "name": "Hong Kong"
    },
    {
        "code": "HMD",
        "name": "Heard Island and McDonald Islands"
    },
    {
        "code": "HND",
        "name": "Honduras"
    },
    {
        "code": "HRV",
        "name": "Croatia"
    },
    {
        "code": "HTI",
        "name": "Haiti"
    },
    {
        "code": "HUN",
        "name": "Hungary"
    },
    {
        "code": "IDN",
        "name": "Indonesia"
    },
    {
        "code": "IRL",
        "name": "Ireland"
    },
    {
        "code": "ISR",
        "name": "Israel"
    },
    {
        "code": "IMN",
        "name": "Isle of Man"
    },
    {
        "code": "IND",
        "name": "India"
    },
    {
        "code": "IOT",
        "name": "British Indian Ocean Territory"
    },
    {
        "code": "IRQ",
        "name": "Iraq"
    },
    {
        "code": "IRN",
        "name": "Iran"
    },
    {
        "code": "ISL",
        "name": "Iceland"
    },
    {
        "code": "ITA",
        "name": "Italy"
    },
    {
        "code": "JEY",
        "name": "Jersey"
    },
    {
        "code": "JAM",
        "name": "Jamaica"
    },
    {
        "code": "JOR",
        "name": "Jordan"
    },
    {
        "code": "JPN",
        "name": "Japan"
    },
    {
        "code": "KEN",
        "name": "Kenya"
    },
    {
        "code": "KGZ",
        "name": "Kyrgyzstan"
    },
    {
        "code": "KHM",
        "name": "Cambodia"
    },
    {
        "code": "KIR",
        "name": "Kiribati"
    },
    {
        "code": "COM",
        "name": "Comoros"
    },
    {
        "code": "KNA",
        "name": "Saint Kitts and Nevis"
    },
    {
        "code": "PRK",
        "name": "North Korea"
    },
    {
        "code": "KOR",
        "name": "South Korea"
    },
    {
        "code": "KWT",
        "name": "Kuwait"
    },
    {
        "code": "CYM",
        "name": "Cayman Islands"
    },
    {
        "code": "KAZ",
        "name": "Kazakhstan"
    },
    {
        "code": "LAO",
        "name": "Laos"
    },
    {
        "code": "LBN",
        "name": "Lebanon"
    },
    {
        "code": "LCA",
        "name": "Saint Lucia"
    },
    {
        "code": "LIE",
        "name": "Liechtenstein"
    },
    {
        "code": "LKA",
        "name": "Sri Lanka"
    },
    {
        "code": "LBR",
        "name": "Liberia"
    },
    {
        "code": "LSO",
        "name": "Lesotho"
    },
    {
        "code": "LTU",
        "name": "Lithuania"
    },
    {
        "code": "LUX",
        "name": "Luxembourg"
    },
    {
        "code": "LVA",
        "name": "Latvia"
    },
    {
        "code": "LBY",
        "name": "Libya"
    },
    {
        "code": "MAR",
        "name": "Morocco"
    },
    {
        "code": "MCO",
        "name": "Monaco"
    },
    {
        "code": "MDA",
        "name": "Moldova"
    },
    {
        "code": "MNE",
        "name": "Montenegro"
    },
    {
        "code": "MAF",
        "name": "Saint Martin"
    },
    {
        "code": "MDG",
        "name": "Madagascar"
    },
    {
        "code": "MHL",
        "name": "Marshall Islands"
    },
    {
        "code": "MKD",
        "name": "Macedonia"
    },
    {
        "code": "MLI",
        "name": "Mali"
    },
    {
        "code": "MMR",
        "name": "Myanmar"
    },
    {
        "code": "MNG",
        "name": "Mongolia"
    },
    {
        "code": "MAC",
        "name": "Macao"
    },
    {
        "code": "MNP",
        "name": "Northern Mariana Islands"
    },
    {
        "code": "MTQ",
        "name": "Martinique"
    },
    {
        "code": "MRT",
        "name": "Mauritania"
    },
    {
        "code": "MSR",
        "name": "Montserrat"
    },
    {
        "code": "MLT",
        "name": "Malta"
    },
    {
        "code": "MUS",
        "name": "Mauritius"
    },
    {
        "code": "MDV",
        "name": "Maldives"
    },
    {
        "code": "MWI",
        "name": "Malawi"
    },
    {
        "code": "MEX",
        "name": "Mexico"
    },
    {
        "code": "MYS",
        "name": "Malaysia"
    },
    {
        "code": "MOZ",
        "name": "Mozambique"
    },
    {
        "code": "NAM",
        "name": "Namibia"
    },
    {
        "code": "NCL",
        "name": "New Caledonia"
    },
    {
        "code": "NER",
        "name": "Niger"
    },
    {
        "code": "NFK",
        "name": "Norfolk Island"
    },
    {
        "code": "NGA",
        "name": "Nigeria"
    },
    {
        "code": "NIC",
        "name": "Nicaragua"
    },
    {
        "code": "NLD",
        "name": "Netherlands"
    },
    {
        "code": "NOR",
        "name": "Norway"
    },
    {
        "code": "NPL",
        "name": "Nepal"
    },
    {
        "code": "NRU",
        "name": "Nauru"
    },
    {
        "code": "NIU",
        "name": "Niue"
    },
    {
        "code": "NZL",
        "name": "New Zealand"
    },
    {
        "code": "OMN",
        "name": "Oman"
    },
    {
        "code": "PAN",
        "name": "Panama"
    },
    {
        "code": "PER",
        "name": "Peru"
    },
    {
        "code": "PYF",
        "name": "French Polynesia"
    },
    {
        "code": "PNG",
        "name": "Papua New Guinea"
    },
    {
        "code": "PHL",
        "name": "Philippines"
    },
    {
        "code": "PAK",
        "name": "Pakistan"
    },
    {
        "code": "POL",
        "name": "Poland"
    },
    {
        "code": "SPM",
        "name": "Saint Pierre and Miquelon"
    },
    {
        "code": "PCN",
        "name": "Pitcairn"
    },
    {
        "code": "PRI",
        "name": "Puerto Rico"
    },
    {
        "code": "PSE",
        "name": "Palestinian Territory"
    },
    {
        "code": "PRT",
        "name": "Portugal"
    },
    {
        "code": "PLW",
        "name": "Palau"
    },
    {
        "code": "PRY",
        "name": "Paraguay"
    },
    {
        "code": "QAT",
        "name": "Qatar"
    },
    {
        "code": "REU",
        "name": "Reunion"
    },
    {
        "code": "ROU",
        "name": "Romania"
    },
    {
        "code": "SRB",
        "name": "Serbia"
    },
    {
        "code": "RUS",
        "name": "Russia"
    },
    {
        "code": "RWA",
        "name": "Rwanda"
    },
    {
        "code": "SAU",
        "name": "Saudi Arabia"
    },
    {
        "code": "SLB",
        "name": "Solomon Islands"
    },
    {
        "code": "SYC",
        "name": "Seychelles"
    },
    {
        "code": "SDN",
        "name": "Sudan"
    },
    {
        "code": "SWE",
        "name": "Sweden"
    },
    {
        "code": "SGP",
        "name": "Singapore"
    },
    {
        "code": "SHN",
        "name": "Saint Helena"
    },
    {
        "code": "SVN",
        "name": "Slovenia"
    },
    {
        "code": "SJM",
        "name": "Svalbard and Jan Mayen"
    },
    {
        "code": "SVK",
        "name": "Slovakia"
    },
    {
        "code": "SLE",
        "name": "Sierra Leone"
    },
    {
        "code": "SMR",
        "name": "San Marino"
    },
    {
        "code": "SEN",
        "name": "Senegal"
    },
    {
        "code": "SOM",
        "name": "Somalia"
    },
    {
        "code": "SUR",
        "name": "Suriname"
    },
    {
        "code": "SSD",
        "name": "South Sudan"
    },
    {
        "code": "STP",
        "name": "Sao Tome and Principe"
    },
    {
        "code": "SLV",
        "name": "El Salvador"
    },
    {
        "code": "SXM",
        "name": "Sint Maarten"
    },
    {
        "code": "SYR",
        "name": "Syria"
    },
    {
        "code": "SWZ",
        "name": "Swaziland"
    },
    {
        "code": "TCA",
        "name": "Turks and Caicos Islands"
    },
    {
        "code": "TCD",
        "name": "Chad"
    },
    {
        "code": "ATF",
        "name": "French Southern Territories"
    },
    {
        "code": "TGO",
        "name": "Togo"
    },
    {
        "code": "THA",
        "name": "Thailand"
    },
    {
        "code": "TJK",
        "name": "Tajikistan"
    },
    {
        "code": "TKL",
        "name": "Tokelau"
    },
    {
        "code": "TLS",
        "name": "Timor Leste"
    },
    {
        "code": "TKM",
        "name": "Turkmenistan"
    },
    {
        "code": "TUN",
        "name": "Tunisia"
    },
    {
        "code": "TON",
        "name": "Tonga"
    },
    {
        "code": "TUR",
        "name": "Turkey"
    },
    {
        "code": "TTO",
        "name": "Trinidad and Tobago"
    },
    {
        "code": "TUV",
        "name": "Tuvalu"
    },
    {
        "code": "TWN",
        "name": "Taiwan"
    },
    {
        "code": "TZA",
        "name": "Tanzania"
    },
    {
        "code": "UKR",
        "name": "Ukraine"
    },
    {
        "code": "UGA",
        "name": "Uganda"
    },
    {
        "code": "UMI",
        "name": "United States Minor Outlying Islands"
    },
    {
        "code": "USA",
        "name": "United States"
    },
    {
        "code": "URY",
        "name": "Uruguay"
    },
    {
        "code": "UZB",
        "name": "Uzbekistan"
    },
    {
        "code": "VAT",
        "name": "Vatican"
    },
    {
        "code": "VCT",
        "name": "Saint Vincent and the Grenadines"
    },
    {
        "code": "VEN",
        "name": "Venezuela"
    },
    {
        "code": "VGB",
        "name": "British Virgin Islands"
    },
    {
        "code": "VIR",
        "name": "U.S. Virgin Islands"
    },
    {
        "code": "VNM",
        "name": "Vietnam"
    },
    {
        "code": "VUT",
        "name": "Vanuatu"
    },
    {
        "code": "WLF",
        "name": "Wallis and Futuna"
    },
    {
        "code": "WSM",
        "name": "Samoa"
    },
    {
        "code": "XKX",
        "name": "Kosovo"
    },
    {
        "code": "YEM",
        "name": "Yemen"
    },
    {
        "code": "MYT",
        "name": "Mayotte"
    },
    {
        "code": "ZAF",
        "name": "South Africa"
    },
    {
        "code": "ZMB",
        "name": "Zambia"
    },
    {
        "code": "ZWE",
        "name": "Zimbabwe"
    }
]