import React, {useContext, useState} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import SideBar from "./components/sidebar/SideBar";
import Content from "./components/content/Content";
import "./App.css";

const CredentialsContext = React.createContext( null );
const SetCredentialsContext = React.createContext( () => null );


export const useCredentials = () => {
    return useContext(CredentialsContext);
}

export const useSetCredentials = () => {
    return useContext(SetCredentialsContext);
}

const App = () => {
    const [credentials, setCredentials] = useState( null );
    const [sidebarIsOpen, setSidebarOpen] = useState( true );
    const toggleSidebar = () => setSidebarOpen( !sidebarIsOpen );

    return (
        <CredentialsContext.Provider value={credentials}>
            <SetCredentialsContext.Provider value={setCredentials}>
                <Router>
                    <div className="App wrapper">
                        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen}/>
                        <Content toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen}/>
                    </div>
                </Router>
            </SetCredentialsContext.Provider>
        </CredentialsContext.Provider>

    );
};

export default App;
